import { ConvertMarketFormProps } from 'pages/markets/merge/form/types'
import { convertFormToFormDto } from 'pages/markets/merge/utils'
import { uniqBy } from 'utils/common'

export const convertFormToPreview = ({ values, data }: ConvertMarketFormProps) => {
  const formDTO = convertFormToFormDto({ values, data })

  const entityLogo = data.find(entity => entity.logoThumbnail?.key === values.logoThumbnail)

  const parent = data.find(entity => entity?.parent?.id === formDTO.parentId)?.parent

  const geographies = uniqBy(
    data.flatMap(entity => entity.geographies.filter(geography => formDTO.geographyIds.includes(geography.id))),
    entity => entity.id,
  )

  const currencies = uniqBy(
    data.flatMap(entity => entity.currencies.filter(currency => formDTO.currencyIds?.includes(currency.id))),
    entity => entity.id,
  )

  return {
    ...formDTO,
    logoThumbnail: entityLogo?.logoThumbnail!,
    logoOriginal: entityLogo?.logoOriginal!,
    geographies,
    currencies,
    parent,
  }
}
