import { convertFormValuesFieldToFormDTO } from 'components/merge/utils'
import { defaultFormValues } from 'pages/markets/merge/form/defaultValues'
import { ConvertMarketFormProps, MergeMarketsForm } from 'pages/markets/merge/form/types'
import { convertToTwoEntityValues } from 'pages/markets/merge/twoEntities/utils'
import { Market, MarketFormDTO } from 'types/masterData/markets'
import { arrayFieldDefaultValue } from 'utils/merge/utils'

export const convertToForm = ([entry1, entry2]: Market[]): MergeMarketsForm => {
  const defaultParentId = entry1.parent?.id || entry2.parent?.id

  const logoThumbnail = entry1.logoThumbnail?.key || entry2.logoThumbnail?.key
  const type = entry1.type || entry2.type

  return {
    ...defaultFormValues,
    name: entry1.name,
    type: type || null,
    logoThumbnail: logoThumbnail || null,
    aliases: arrayFieldDefaultValue(entry1.aliases.length, entry2.aliases.length),
    parentId: defaultParentId || null,
    geographyIds: arrayFieldDefaultValue(entry1.geographies.length, entry2.geographies.length),
    currencyIds: arrayFieldDefaultValue(entry1.currencies.length, entry2.currencies.length),
    isoAlpha2: entry1.isoAlpha2 || entry2.isoAlpha2,
    isoAlpha3: entry1.isoAlpha3 || entry2.isoAlpha3,
  }
}

export const convertFormToFormDto = ({ values, data }: ConvertMarketFormProps): MarketFormDTO => {
  const formValues = convertToTwoEntityValues(data)
  const name = values.isNewName ? values.newName : values.name

  const aliases = convertFormValuesFieldToFormDTO({
    formValues,
    values,
    field: 'aliases',
  })

  const geographyIds = convertFormValuesFieldToFormDTO({
    formValues,
    values,
    valueGetterFn: item => item.id?.toString(),
    field: 'geographyIds',
  })

  const currencyIds = convertFormValuesFieldToFormDTO({
    formValues,
    values,
    valueGetterFn: item => item.id?.toString(),
    field: 'currencyIds',
  })

  const entityLogo = data.find(entity => entity.logoThumbnail?.key === values.logoThumbnail)

  return {
    name,
    type: data?.[0]?.type,
    aliases,
    parentId: values.parentId,
    logoThumbnail: entityLogo?.logoThumbnail
      ? {
          name: entityLogo?.logoThumbnail?.name!,
          key: entityLogo?.logoThumbnail?.key!,
          size: entityLogo?.logoThumbnail?.size!,
        }
      : null,
    logoOriginal: entityLogo?.logoOriginal
      ? {
          name: entityLogo?.logoOriginal?.name!,
          key: entityLogo?.logoOriginal?.key!,
          size: entityLogo?.logoOriginal?.size!,
        }
      : null,
    isoAlpha2: values.isoAlpha2!,
    isoAlpha3: values.isoAlpha3!,
    geographyIds,
    currencyIds,
  }
}
